@import "../../../variables";

.loginPage {
  background-color: $mainColor;
  .eye.btn {
    right: 1rem;
    @include transformCenterY;
    &.on {
      opacity: $disabledOp;
    }
  }
  > div {
    background-color: $mainColorDark;
  }
  > .loginCard {
    border-radius: $smallCardRadius;
    margin: auto;
    width: $loginCardWidth;
  }
}
