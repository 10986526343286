//@import "../../variables";
//
//.mainHeader {
//  background-color: $mainColor;
//  color: $whiteColor;
//  .o1,
//  .o2,
//  .o3,
//  .o4,
//  .o5,
//  .o6,
//  .o7 {
//    display: none;
//  }
//}
//
//@media (min-width: 992px) {
//  .mainHeader {
//    .o1,
//    .o2 {
//      display: block;
//    }
//  }
//}
//
//@media (min-width: 1200px) {
//  .mainHeader {
//    .o3,
//    .o4 {
//      display: block;
//    }
//  }
//}
@import "../../variables";

.mainHeader {
  background-color: $mainColor;
  color: $whiteColor;
  height: $mainHeaderHeight;
}
