@import "../../variables";

.sideMenu {
  z-index: 1;
  .sideMenuContent {
    background-color: $mainColor;
    color: $whiteColor;
    width: $sideMenuWidth;
    min-height: 100%; //must use min-height for correct bg display
    > div > a {
      display: block;
    }
    > div:hover > a,
    > div.selected > a {
      background-color: $yellowColor;
      margin-left: 0.125rem !important;
      margin-right: 0.125rem !important;
      cursor: pointer;
      border-radius: 2px;
      color: $whiteColor;
    }
    > .row:not(.selected):not(:hover) {
      margin-right: 0;
    }
    a:focus {
      box-shadow: unset;
      outline: none;
    }
  }
}
