@import "../../../../variables";
.chart-container {
  width: 100%;
  height: 100%;
  position: relative;
  .ant-empty-image {
    position: unset;
    svg {
      width: 70%;
      height: 70%;
    }
  }
  .chart-container-parent {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
  .col-12 {
    position: relative;
    .scatter-line-container {
      position: absolute;
      width: 100%;
      height: 83%;
      top: 0;
      left: 0;
      background: transparent;
      color: $whiteColor;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 10px;
      .line-item {
        display: flex;
        align-items: center;
        .deg {
          display: inline-block;
          width: 3rem;
          font-size: 0.85rem;
          text-align: right;
          padding-right: 5px;
        }
        .line {
          width: 95%;
          border-top: 1px solid $whiteColor;
        }
      }
      .active {
        .line {
          border-top: 3px solid $yellowColor;
        }
      }
    }
  }
  .ant-select,
  .see-more {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    .ant-select-selector {
      height: 1.4rem !important;
      position: relative;
      top: 1px;
      font-size: 0.71rem;
    }
  }
  .desc {
    height: 100%;
    color: #c4c4c4;
    font-size: 1.14rem;
    font-family: Inter;
    justify-content: space-evenly;
    .highlighted {
      color: $whiteColor;
      font-weight: bold;
      span {
        padding-left: 2px;
        padding-right: 2px;
        color: #c4c4c4;
      }
    }
  }
  .all-info-and-percent-box {
    .info-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      > div {
        flex: 1.5;
        //margin-bottom: 5px;
      }
      > div:nth-child(1) {
        flex: 3;
      }
      > div:nth-child(2) {
        flex: 2;
      }
    }
  }
  .all-info-and-percent-box:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .compare {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .chart-right {
    display: flex;
    height: 100%;
    font-size: 1rem;
    .percentValue {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: start;
      color: $whiteColor;
      .percentSign {
        font-size: 1.14rem;
        font-weight: normal;
        display: flex;
        align-items: end;
        position: relative;
        top: -10px;
      }
    }
    .percentValue:nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }
  .box {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    .title {
      display: flex;
      justify-content: end;
      color: $whiteColor;
      font-size: 0.85rem;
      position: relative;
      top: -10px;
    }
    .echarts-for-react {
      width: 100%;
      height: 100%;
      canvas {
        z-index: 0 !important;
      }
    }
  }
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-spin-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-spin-blur {
      opacity: 1;
    }
  }
  .ant-spin-nested-loading .ant-spin-container::after {
    background: transparent;
  }
}
.chart-container.heatmap {
  margin-top: 0px !important;
  position: relative;
  .ant-select {
    position: absolute;
    right: 3px;
    top: -26px;
    width: 10rem;
    z-index: 9;
    .ant-select-selector {
      color: $mainColor;
      border-color: $yellowColor !important;
      border-width: 0.5px;
      font-size: 0.9rem;
      padding: 0 0 0 4px !important;
      height: 1.8rem !important;
    }
  }
  .ant-select-single.ant-select-sm.ant-select-open .ant-select-selection-item {
    color: $mainColor;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: $yellowColor !important;
  }
}
.chart-container.lineChart,
.chart-container.heatmap {
  .unit {
    position: absolute;
    bottom: -4px;
    right: 7px;
    color: $whiteColor;
    font-size: 0.8rem;
  }
}
.ant-modal-root .ant-modal-wrap {
  height: 100%;
  .custom-modal-content {
    width: 45rem !important;
    top: 20px !important;
    .ant-modal-content {
      background-color: $whiteColor;
      padding: 10px 10px;
      .anticon.anticon-close {
        color: $yellowColor;
      }
    }
    .ant-modal-header {
      .ant-modal-title {
        background: $whiteColor;
        color: $mainColor;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 60px - 52px);
      .echarts-for-react {
        height: 100%;
      }
    }
  }
}
