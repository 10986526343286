$mainColorDarkerCode: 0d2940;
$mainColorDarkCode: 273655;
$mainColorCode: 4d648d;
$whiteColorCode: FFFFFF;
$blueColorCode: 4d648d; //2d6a9a
$yellowColorCode: FAA855;
$greenColorCode: 75c639;

$mainColorDarker: #0d2940;
$mainColorDark: #273655;

$mainColor: #4d648d;
$mainLightColor: rgba(77, 100, 141, 0.7);
$blueColor: $mainColor; //#2d6a9a
$redColor: #ff5b74;
$lightBlueColor: #2fa0b0; //TODOricky check remove?
$coolerBlueColor: #5585ff;
$coolerBlueColorTransparent: rgba(85, 133, 255, 0.5);
$warmerRedColor: #ff5b74;
$warmerRedColorTransparent: rgba(255, 91, 116, 0.5);
$normalTempColor: #ffd191;
$normalTempColorTransparent: rgba(255, 209, 145, 0.5);
$noTempColor: #2fd38e;
$noTempColorTransparent: rgba(47, 211, 142, 0.5);
$yellowColor: #ffa500; //old code #faa855
$greenColor: #75c639;
$yellowGreenColor: #dadc73;
$whiteColor: #ffffff;
$greyestColor: #626262;
$greyerColor: #afafaf;
$greyColor: #c4c4c4;
$lightGreyColor: #f4f3f0;
$blueGreyColor: #edf2f7;
$bg: #e2e8f0;

$whiteLayerColor: rgba(255, 255, 255, 0.4);
$whiterLayerColor: rgba(255, 255, 255, 0.8);
$darkLayerColor: rgba(0, 0, 0, 0.1);
$darkerLayerColor: rgba(98, 98, 98, 0.72);

$disabledOp: 0.6;
$focusShadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

$inputColor: $mainColorDarker;

$ctrlPrimaryColor: $mainColor;
$ctrlBgColor: $greyColor;

/*$btnShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
$btnRadius: 0.5rem;
$btnBorderWidth: 0.2rem;
$btnBorderWidthThin: 1px;
$smallCardRadius: 0.2rem;
$smallBtnRadius: 0.2rem;
$btnBorderWidth: 1px;

$btnPadH: 0.375rem;
$btnPadV: 0.75rem;

$scrollBarWidth: 3px;

$cardRadius: 1rem;
$smallCardRadius: 0.5rem;
$modalShadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

$sliderBtnSize: 1.3rem;
$dotSize: 1.5rem;

$dividerColor: $greyerColor;
$lightDividerColor: #dadada;
$dividerWidth: 1px;
$dividerWidthThick: 2px;

$headerShift: 3rem;

$loginCardWidth: 26rem;

$contentMargin: 2.5rem;

$sideMenuWidth: 5rem;
$mainHeaderHeight: 5rem;
@mixin successYellowBg {
  background-image: url(/images/successYellow.png);
}
@mixin star1Bg {
  background-image: url(/images/star1.png);
}
@mixin star2Bg {
  background-image: url(/images/star2.png);
}
@mixin star3Bg {
  background-image: url(/images/star3.png);
}
@mixin star4Bg {
  background-image: url(/images/star4.png);
}
@mixin star5Bg {
  background-image: url(/images/star5.png);
}
@mixin marker {
  background-image: url(/images/marker.png);
}
@mixin sideMenuDashboardIcon {
  background-image: url(/images/dashboard/dashboardIcon.png);
}
@mixin headerEllipsisIcon {
  background-image: url(/images/dashboard/ellipsis.png);
}
@mixin headerDeleteIcon {
  background-image: url(/images/dashboard/delete.png);
}
@mixin headerDuplicateIcon {
  background-image: url(/images/dashboard/copy.png);
}
@mixin sideMenuInsightsIcon {
  background-image: url(/images/dashboard/insightIcon.png);
}
@mixin LeftArrowInsightsIcon {
  background-image: url(/images/dashboard/leftarrow.png);
}
@mixin RightArrowInsightsIcon {
  background-image: url(/images/dashboard/rightarrow.png);
}
@mixin sideMenAccountUserIcon {
  background-image: url(/images/dashboard/accountUserIcon.png);
}
@mixin logoBg {
  background-image: url(/images/logo.png);
}
@mixin shortLogoBg {
  background-image: url(/images/shortLogo.png);
}
@mixin msLogoBg {
  background-image: url(/images/msLogo.png);
}
@mixin detailBg {
  background-image: url(/images/detail.png);
}
@mixin refreshBg {
  background-image: url(/images/refresh.png);
}
@mixin profileBg {
  background-image: url(/images/user.png);
}
@mixin starWhiteBg {
  background-image: url(/images/starWhite.png);
}
@mixin starYellowBg {
  background-image: url(/images/starYellow.png);
}
@mixin decreaseYellowBg {
  background-image: url(/images/decreaseYellow.png);
}
@mixin iaqWhiteBg {
  background-image: url(/images/iaqWhite.png);
}
@mixin iaqBlueBg {
  background-image: url(/images/dashboard/iaqBlue.png);
}
@mixin iaqYellowBg {
  background-image: url(/images/iaqYellow.png);
}
@mixin downArrowBg($colorCode: $mainColorCode) {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' fill='%23#{$colorCode}'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@mixin temperatureBg($colorCode: $whiteColorCode) {
  background-image: url('data:image/svg+xml,%3csvg viewBox="0 0 19 37" xmlns="http://www.w3.org/2000/svg" fill="%23#{$colorCode}"%3e%3cpath d="M9.50001 0.5C7.70979 0.5 5.99291 1.21116 4.72703 2.47703C3.46116 3.7429 2.75001 5.45979 2.75001 7.25V21.5465C1.60438 22.8455 0.857916 24.4475 0.600196 26.1603C0.342475 27.873 0.584443 29.6237 1.29706 31.2024C2.00969 32.781 3.16269 34.1205 4.61771 35.0601C6.07274 35.9997 7.76798 36.4994 9.50001 36.4994C11.232 36.4994 12.9273 35.9997 14.3823 35.0601C15.8373 34.1205 16.9903 32.781 17.7029 31.2024C18.4156 29.6237 18.6575 27.873 18.3998 26.1603C18.1421 24.4475 17.3956 22.8455 16.25 21.5465V7.25C16.25 5.45979 15.5388 3.7429 14.273 2.47703C13.0071 1.21116 11.2902 0.5 9.50001 0.5ZM9.50001 2.75C10.6935 2.75 11.8381 3.22411 12.682 4.06802C13.5259 4.91193 14 6.05653 14 7.25V22.4487L14.3218 22.775C15.2519 23.7244 15.881 24.9273 16.1303 26.2329C16.3795 27.5384 16.2378 28.8885 15.7228 30.1138C15.2079 31.3391 14.3427 32.3851 13.2357 33.1207C12.1287 33.8563 10.8291 34.2487 9.50001 34.2487C8.17089 34.2487 6.87135 33.8563 5.76433 33.1207C4.65732 32.3851 3.7921 31.3391 3.27717 30.1138C2.76224 28.8885 2.62051 27.5384 2.86976 26.2329C3.119 24.9273 3.74813 23.7244 4.67826 22.775L5.00001 22.4487V7.25C5.00001 6.05653 5.47411 4.91193 6.31803 4.06802C7.16194 3.22411 8.30653 2.75 9.50001 2.75Z"/%3e%3cpath d="M9.50001 10.625C9.79838 10.625 10.0845 10.7435 10.2955 10.9545C10.5065 11.1655 10.625 11.4516 10.625 11.75V23.1417C11.6855 23.4156 12.6097 24.0668 13.2244 24.9733C13.8392 25.8798 14.1022 26.9793 13.9641 28.0659C13.8261 29.1524 13.2966 30.1513 12.4748 30.8754C11.653 31.5994 10.5953 31.9989 9.50001 31.9989C8.40474 31.9989 7.34707 31.5994 6.52525 30.8754C5.70343 30.1513 5.17389 29.1524 5.03588 28.0659C4.89787 26.9793 5.16087 25.8798 5.77558 24.9733C6.3903 24.0668 7.31452 23.4156 8.37501 23.1417V11.75C8.37501 11.4516 8.49354 11.1655 8.70451 10.9545C8.91549 10.7435 9.20164 10.625 9.50001 10.625Z"/%3e%3c/svg%3e');
}
@mixin humidityBg {
  background-image: url(/images/humidity.png);
}
@mixin stopHandYellowBg {
  background-image: url(/images/stopHandYellow.png);
}
@mixin energyWhiteBg {
  background-image: url(/images/energyWhite.png);
}
@mixin requestSummaryWhiteBg {
  background-image: url(/images/requestSummaryWhite.png);
}
@mixin controlOthersWhiteBg {
  background-image: url(/images/controlOthersWhite.png);
}
@mixin leaveBg {
  background-image: url(/images/leave.png);
}
@mixin lightOffBg($colorCode: $whiteColorCode) {
  background-image: url('data:image/svg+xml,%3csvg viewBox="0 0 25 31" xmlns="http://www.w3.org/2000/svg" fill="none"%3e%3cpath stroke="%23#{$colorCode}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.72784 21.7137H18.0917M5.06867 18.681C3.51813 17.2323 2.46228 15.3867 2.03464 13.3775C1.607 11.3684 1.82678 9.28592 2.66617 7.39343C3.50556 5.50094 4.92689 3.88343 6.75042 2.74542C8.57395 1.60741 10.7178 1 12.9109 1C15.104 1 17.2478 1.60741 19.0714 2.74542C20.8949 3.88343 22.3162 5.50094 23.1556 7.39343C23.995 9.28592 24.2148 11.3684 23.7871 13.3775C23.3595 15.3867 22.3037 17.2323 20.7531 18.681L19.5377 19.8141C18.8429 20.4632 18.2918 21.2338 17.9158 22.0819C17.5398 22.93 17.3464 23.839 17.3465 24.7569V25.8569C17.3465 26.9557 16.8792 28.0095 16.0474 28.7865C15.2155 29.5635 14.0873 30 12.9109 30C11.7345 30 10.6063 29.5635 9.77442 28.7865C8.94257 28.0095 8.47525 26.9557 8.47525 25.8569V24.7569C8.47525 22.9028 7.6857 21.1233 6.28404 19.8141L5.06867 18.681Z"/%3e%3c/svg%3e');
}
@mixin lightOnBg($colorCode: $whiteColorCode) {
  background-image: url(/images/dashboard/lightOn.png);
  //background-image: url('data:image/svg+xml,%3csvg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" fill="none"%3e%3cpath stroke="%23#{$colorCode}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M15.0664 30.5556H24.9317M20.0001 1V3.11111M33.4352 6.56489L31.9427 8.05744M39.0002 20H36.889M3.11112 20H1M8.05747 8.05744L6.56491 6.56489M12.5352 27.4649C11.0592 25.9886 10.0542 24.1077 9.6471 22.0602C9.24003 20.0127 9.44923 17.8905 10.2482 15.9619C11.0473 14.0333 12.4002 12.3849 14.136 11.2252C15.8718 10.0654 17.9125 9.44641 20.0001 9.44641C22.0877 9.44641 24.1284 10.0654 25.8642 11.2252C27.6 12.3849 28.9529 14.0333 29.7519 15.9619C30.5509 17.8905 30.7601 20.0127 30.3531 22.0602C29.946 24.1077 28.9409 25.9886 27.465 27.4649L26.3081 28.6197C25.6467 29.2812 25.1221 30.0665 24.7642 30.9308C24.4063 31.795 24.2222 32.7213 24.2223 33.6568V34.7778C24.2223 35.8976 23.7775 36.9715 22.9857 37.7633C22.1938 38.5552 21.1199 39 20.0001 39C18.8803 39 17.8063 38.5552 17.0145 37.7633C16.2227 36.9715 15.7778 35.8976 15.7778 34.7778V33.6568C15.7778 31.7673 15.0263 29.9539 13.6921 28.6197L12.5352 27.4649Z"/%3e%3c/svg%3e');
}
@mixin airconWhiteBg {
  background-image: url(/images/airconWhite.png);
}
@mixin airconBlueBg {
  //background-image: url(/images/airconBlue.png);
}
@mixin airconYellowBg {
  background-image: url(/images/airconYellow.png);
}
@mixin buttonWhiteBg {
  background-image: url(/images/buttonWhite.png);
}
@mixin buttonBlueBg {
  background-image: url(/images/buttonBlue.png);
}
@mixin buttonYellowBg {
  background-image: url(/images/buttonYellow.png);
}
@mixin sensorWhiteBg {
  background-image: url(/images/sensorWhite.png);
}
@mixin sensorBlueBg {
  background-image: url(/images/sensorBlue.png);
}
@mixin sensorYellowBg {
  background-image: url(/images/sensorYellow.png);
}
@mixin switchWhiteBg {
  background-image: url(/images/switchWhite.png);
}
@mixin switchBlueBg {
  background-image: url(/images/switchBlue.png);
}
@mixin switchYellowBg {
  background-image: url(/images/switchYellow.png);
}
@mixin fanWhiteBg {
  background-image: url(/images/fanWhite.png);
}
@mixin fanBlueBg {
  background-image: url(/images/fanBlue.png);
}
@mixin fanYellowBg {
  background-image: url(/images/fanYellow.png);
}
@mixin locationMarkBg {
  background-image: url('data:image/svg+xml,%3csvg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M15 0.5C18.9782 0.5 22.7936 2.08035 25.6066 4.8934C28.4196 7.70644 30 11.5218 30 15.5C30 22.6675 25.1625 29.265 15.67 35.305C15.4697 35.4322 15.2373 35.4997 15 35.4997C14.7627 35.4997 14.5303 35.4322 14.33 35.305C4.8375 29.265 0 22.6675 0 15.5C0 11.5218 1.58035 7.70644 4.3934 4.8934C7.20644 2.08035 11.0218 0.5 15 0.5ZM15 3C11.6848 3 8.50537 4.31696 6.16117 6.66117C3.81696 9.00537 2.5 12.1848 2.5 15.5C2.5 21.3125 6.35 26.895 14.2 32.23L15 32.7625L15.8 32.23C23.65 26.895 27.5 21.3125 27.5 15.5C27.5 12.1848 26.183 9.00537 23.8388 6.66117C21.4946 4.31696 18.3152 3 15 3ZM15 11.75C15.4925 11.75 15.9801 11.847 16.4351 12.0355C16.89 12.2239 17.3034 12.5001 17.6517 12.8483C17.9999 13.1966 18.2761 13.61 18.4645 14.0649C18.653 14.5199 18.75 15.0075 18.75 15.5C18.75 15.9925 18.653 16.4801 18.4645 16.9351C18.2761 17.39 17.9999 17.8034 17.6517 18.1517C17.3034 18.4999 16.89 18.7761 16.4351 18.9645C15.9801 19.153 15.4925 19.25 15 19.25C14.0054 19.25 13.0516 18.8549 12.3483 18.1517C11.6451 17.4484 11.25 16.4946 11.25 15.5C11.25 14.5054 11.6451 13.5516 12.3483 12.8483C13.0516 12.1451 14.0054 11.75 15 11.75Z" fill="%23FF0312"/%3e%3c/svg%3e');
}
@mixin eyeBg($colorCode: $mainColorCode) {
  background-image: url('data:image/svg+xml,%3csvg enable-background="new -0.709 -32.081 141.732 141.732" height="141.732px" version="1.1" viewBox="-0.709 -32.081 141.732 141.732" width="141.732px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"%3e%3cg%3e%3cpath fill="%23#{$colorCode}" d="M89.668,38.786c0-10.773-8.731-19.512-19.51-19.512S50.646,28.01,50.646,38.786c0,10.774,8.732,19.511,19.512,19.511   C80.934,58.297,89.668,49.561,89.668,38.786 M128.352,38.727c-13.315,17.599-34.426,28.972-58.193,28.972   c-23.77,0-44.879-11.373-58.194-28.972C25.279,21.129,46.389,9.756,70.158,9.756C93.927,9.756,115.036,21.129,128.352,38.727    M140.314,38.76C125.666,15.478,99.725,0,70.158,0S14.648,15.478,0,38.76c14.648,23.312,40.591,38.81,70.158,38.81   S125.666,62.072,140.314,38.76"/%3e%3c/g%3e%3c/svg%3e');
}
@mixin tickBg($colorCode: $yellowColorCode) {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none"%3e%3cpath fill-rule="evenodd" clip-rule="evenodd" d="M8.38969 1.31199L4.07169 6.71099L0.679688 3.88399L1.31969 3.11599L3.92769 5.28899L7.60969 0.687988L8.38969 1.31199Z" fill="%23#{$colorCode}"/%3e%3c/svg%3e');
}
@mixin roomBg {
  background-image: url(/images/room.png);
}
@mixin workstationBg {
  background-image: url(/images/fixdesk.png);
}
@mixin hotdeskBg {
  background-image: url(/images/hotdesk.png);
}
@mixin floorBg {
  background-image: url(/images/floor.png);
}
@mixin roomYellowBg {
  background-image: url(/images/roomYellow.png);
}
@mixin workstationYellowBg {
  background-image: url(/images/fixdeskYellow.png);
}
@mixin hotdeskYellowBg {
  background-image: url(/images/hotdeskYellow.png);
}
@mixin floorYellowBg {
  background-image: url(/images/floorYellow.png);
}
@mixin roomWhiteBg {
  background-image: url(/images/roomWhite.png);
}
@mixin workstationWhiteBg {
  background-image: url(/images/fixdeskWhite.png);
}
@mixin hotdeskWhiteBg {
  background-image: url(/images/hotdeskWhite.png);
}
@mixin floorWhiteBg {
  background-image: url(/images/floorWhite.png);
}
@mixin emptyBg {
  background-image: url(/images/empty.png);
}
@mixin searchBg {
  background-image: url(/images/search.png);
}
@mixin removeYellowBg {
  background-image: url(/images/remove.png);
}
@mixin penYellowBg {
  background-image: url(/images/penYellow.png);
}
@mixin crossYellowBg {
  background-image: url(/images/crossYellow.png);
}
@mixin warningYellowBg {
  background-image: url(/images/warningYellow.png);
}
@mixin listYellowBg {
  background-image: url(/images/listYellow.png);
}
@mixin mapYellowBg {
  background-image: url(/images/mapYellow.png);
}
@mixin clockWhiteBg {
  background-image: url(/images/clockWhite.png);
}

@mixin sunnyBg {
  background-image: url(/images/sunny.png);
}
@mixin partlycloudyBg {
  background-image: url(/images/partlycloudy.png);
}
@mixin cloudyBg {
  background-image: url(/images/cloudy.png);
}
@mixin rainBg {
  background-image: url(/images/rain.png);
}
@mixin snowBg {
  background-image: url(/images/snow.png);
}
@mixin hailBg {
  background-image: url(/images/hail.png);
}
@mixin sleetBg {
  background-image: url(/images/sleet.png);
}
@mixin fogBg {
  background-image: url(/images/fog.png);
}
@mixin thunderstormBg {
  background-image: url(/images/thunderstorm.png);
}
/**weather bg */

@mixin locationLogo {
  background-image: url(/images/_weather/location-logo.png);
}
@mixin sunnyBg {
  background-image: url(/images/_weather/sunny-bg.png);
}
@mixin snowBg {
  background-image: url(/images/_weather/snow-bg.png);
}
@mixin cloudyNightBg {
  background-image: url(/images/_weather/cloudynight-bg.png);
}
@mixin cloudyBg {
  background-image: url(/images/_weather/cloudy-bg.png);
}
@mixin rainBg {
  background-image: url(/images/_weather/rain-bg.png);
}

@mixin profileAdminisatorIcon {
  background-image: url(/images/administrator.png);
}

@mixin editIcon {
  background-image: url(/images/edit.png);
}

@mixin cancelIcon {
  background-image: url(/images/cancel.png);
}
@mixin saveIcon {
  background-image: url(/images/save.png);
}

@mixin adminWhiteBg {
  background-image: url(/images/adminWhite.png);
}
@mixin controlWhiteBg {
  background-image: url(/images/controlWhite.png);
}
@mixin dashboardWhiteBg {
  background-image: url(/images/dashboardWhite.png);
}
@mixin insightsWhiteBg {
  background-image: url(/images/insightsWhite.png);
}

@mixin insightsCollapseOpen {
  background-image: url(/images/dashboard/collapse-open.png);
}
@mixin insightsCollapseClose {
  background-image: url(/images/dashboard/collapse-close.png);
}
@mixin blueHomeBg {
  background-image: url(/images/blueHome.png);
}
@mixin blueAccountUserMaleBg {
  background-image: url(/images/addUserMale.png);
}
@mixin blueLocationBg {
  background-image: url(/images/blueLocation.png);
}
@mixin blueSceneBg {
  background-image: url(/images/blueScene.png);
}
@mixin yellowSceneBg {
  background-image: url(/images/yellowScene.png);
}
@mixin dashboardWidgetEdit {
  background-image: url(/images/dashboard/pencil.png);
}
@mixin dashboardwidgetMove {
  background-image: url(/images/dashboard/move.png);
}
@mixin dashboardwidgetExport {
  background-image: url(/images/dashboard/export.png);
}
@mixin dashboardwidgetCodeWall {
  background-image: url(/images/dashboard/coderwall.png);
}

@mixin dashboardwidgetConflict {
  background-image: url(/images/dashboard/conflict.png);
}
@mixin dashboardwidgetlightOn {
  background-image: url(/images/dashboard/lightOn.png);
}
@mixin dashboardwidgetAircon {
  background-image: url(/images/dashboard/airconBlue.png);
}
@mixin dashboardwidgetCo2 {
  background-image: url(/images/dashboard/widgetCo2.png);
}

@mixin dashboardwidgetWater {
  background-image: url(/images/dashboard/water.png);
}
@mixin dashboardwidgetTemperature {
  background-image: url(/images/dashboard/temperature.png);
}

@mixin dashboardwidgetStar {
  background-image: url(/images/dashboard/star.png);
}
@mixin dashboardwidgetCartoonGrade1 {
  background-image: url(/images/dashboard/score-right-bg1.png);
}
@mixin dashboardwidgetCartoonGrade2 {
  background-image: url(/images/dashboard/score-right-bg2.png);
}
@mixin dashboardwidgetCartoonGrade3 {
  background-image: url(/images/dashboard/score-right-bg3.png);
}
@mixin dashboardwidgetCartoonGrade4 {
  background-image: url(/images/dashboard/score-right-bg4.png);
}
@mixin dashboardwidgetCartoonGrade5 {
  background-image: url(/images/dashboard/score-right-bg5.png);
}
@mixin insightsSelectors {
  background-image: url(/images/dashboard/selectors.png);
}
@mixin insightsMenuExpand {
  background-image: url(/images/dashboard/menu-expand.png);
}
@mixin insightsShare {
  background-image: url(/images/dashboard/share.png);
}
@mixin insightsDatapoint {
  background-image: url(/images/dashboard/datapoint.png);
}
@mixin insightsPov {
  background-image: url(/images/dashboard/pov.png);
}
@mixin insightsSites {
  background-image: url(/images/dashboard/sites.png);
}
@mixin insightsMenuPackUp {
  background-image: url(/images/dashboard/menu-pack-up.png);
}

@mixin insightsLocations {
  background-image: url(/images/dashboard/locations.png);
}

@mixin insightsSelectExpandIcon {
  background-image: url(/images/dashboard/expandIcon.png);
}
@mixin insightsLocationsClearAll {
  background-image: url(/images/dashboard/clearSeleted.png);
}
@mixin insightsLocationsRefresh {
  background-image: url(/images/dashboard/refreshIcon.png);
}

@mixin insightsScrubber {
  background-image: url(/images/dashboard/scrubber.png);
}
@mixin insightsScrubberActive {
  background-image: url(/images/dashboard/scrubberActive.png);
}

@mixin insightsDataTable {
  background-image: url(/images/dashboard/datatable.png);
}

@mixin insightsDataTableSearch {
  background-image: url(/images/dashboard/datatable-searh.png);
}

@mixin insightsTrendIconBarChart {
  background-image: url(/images/dashboard/barChart.png);
}
@mixin insightsTrendIconBarChartActive {
  background-image: url(/images/dashboard/barChartActive.png);
}
@mixin insightsTrendIconLinechart {
  background-image: url(/images/dashboard/lineChart.png);
}
@mixin insightsTrendIconLinechartActive {
  background-image: url(/images/dashboard/lineChartActive.png);
}
@mixin insightsTrendIconPieChart {
  background-image: url(/images/dashboard/pieChart.png);
}
@mixin insightsTrendIconPieChartActive {
  background-image: url(/images/dashboard/pieChartActive.png);
}
@mixin insightsTrendIconHeatMapChart {
  background-image: url(/images/dashboard/heatmap.png);
}
@mixin insightsTrendIconHeatMapChartActive {
  background-image: url(/images/dashboard/heatMapActive.png);
}
@mixin insightsTrendIconScoreCardChart {
  background-image: url(/images/dashboard/scoreCard.png);
}
@mixin insightsTrendIconScoreCardChartActive {
  background-image: url(/images/dashboard/scoreCardActive.png);
}
@mixin insightsTrendIconTemperatureChart {
  background-image: url(/images/dashboard/temperatureIcon.png);
}
@mixin insightsTrendIconTemperatureChartActive {
  background-image: url(/images/dashboard/temperatureIconActive.png);
}
@mixin insightsTrendIconBreakdownChart {
  background-image: url(/images/dashboard/breakdownIcon.png);
}
@mixin insightsTrendIconBreakdownChartActive {
  background-image: url(/images/dashboard/breakdownActive.png);
}
@mixin insightsCollapseRightIcon {
  background-image: url(/images/dashboard/collapseIcon.png);
}
@mixin insightsCollapseDarkRightIcon {
  background-image: url(/images/dashboard/darkCollapseRight.png);
}
@mixin editorBg {
  background-image: url(/images/dashboard/editor.png);
}
@mixin viewerBg {
  background-image: url(/images/dashboard/viewer.png);
}
@mixin ownerBg {
  background-image: url(/images/dashboard/owner.png);
}

@mixin light {
  font-weight: 300;
}
@mixin bold {
  font-weight: 600;
}

@mixin big {
  font-size: 1.2rem !important;
}
@mixin bigger {
  font-size: 1.4rem !important;
}
@mixin biggest {
  font-size: 1.6rem !important;
}
@mixin normal {
  font-size: 1rem !important;
}
@mixin normalWeak {
  font-size: 1rem;
}
@mixin small {
  font-size: 0.9rem !important;
}
@mixin smaller {
  font-size: 0.8rem !important;
}
@mixin smallest {
  font-size: 0.7rem !important;
}

@mixin btn($bgColor: transparent, $color: $whiteColor, $borderColor: transparent) {
  border-radius: $btnRadius;
  min-width: 0;
  position: relative;
  border: $btnBorderWidth solid $borderColor;
  color: $color;
  background-color: $bgColor;
  font-size: 1rem;
  line-height: 1.2;
  padding: $btnPadH $btnPadV;
}

@mixin transformCenter($shiftUp: 0%) {
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(50% - #{$shiftUp});
  left: 50%;
}
@mixin transformCenterY($shiftUp: 0%) {
  position: absolute;
  transform: translateY(-50%);
  top: calc(50% - #{$shiftUp});
}

@mixin dot($color) {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-color: $color;
  border: 1px solid $whiteColor;
  border-radius: 1000px;
  width: $dotSize;
  height: $dotSize;
}

/*@mixin triangle($color, $angle, $size) {
  content: "";
  display: inline-block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 $size/2 $size/1.5 $size/2;
  border-color: transparent transparent $color transparent;
  transform: rotate($angle);
}*/
