@import "./variables";

/*********************************************************************************
  with the base font-size = calc(10px + min(0.7vmin, 10px)) and a 1440x1024 screen,
  here are the eqivalent size in px for these classes

  for font:
  smallest = 12.0176px
  smaller = 13.7344px
  small = 15.4512px
  normal = 17.168px
  big = 20.6016px
  bigger = 24.0352px
  biggest = 27.4688px

  padding/margin:
  ps-05 ms-05 = 2.146px
  ps-1 ms-1 = 4.292px
  ps-15 ms-15 = 6.438px
  ps-2 ms-2 = 8.584px
  ps-25 ms-25 = 12.876px
  ps-3 ms-3 = 17.168px
  ps-35 ms-35 = 21.460px
  ps-4 ms-4 = 25.752px
  ps-45 ms-45 = 34.336px
  ps-5 ms-5 = 51.504px
  ps-55 ms-55 = 68.672px
  ps-6 ms-6 = 85.840px

**********************************************************************************/
html {
  font-size: calc(10px + min(0.7vmin, 10px));
}
body {
  overflow: hidden; //this is for account page, able to scroll inside iframe and trigger scroll event
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: $bg;
  color: $mainColor;
  font-family: "Inter", "Source Sans Pro", "Microsoft JhengHei", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}
* {
  font-family: inherit !important;
}
ul {
  padding-left: 0px;
}
@font-face {
  font-family: customInter; /* 字体名称，你可以自定义 */
  src: url("./font/Inter-VariableFont_slnt,wght.ttf") format("truetype"); /* 字体文件路径和格式 */
}

.fontInter {
  font-family: "Inter", sans-serif !important;
}

.big {
  @include big;
}

.bigger {
  @include bigger;
}

.biggest {
  @include biggest;
}

.normal {
  @include normal;
}

.small {
  @include small;
}

.smaller {
  @include smaller;
}

.smallest {
  @include smallest;
}

.bold {
  @include bold;
}

.light {
  @include light;
}

.inputFont {
  color: $inputColor !important;
}
.greyFont {
  color: $greyColor !important;
}
.greyerFont {
  color: $greyerColor !important;
}
.greyestFont {
  color: $greyestColor !important;
}
.whiteFont {
  color: $whiteColor !important;
}
.transparentFont {
  color: transparent !important;
}
.coolerBlueFont {
  color: $coolerBlueColor !important;
}
.lightBlueFont {
  color: $lightBlueColor !important;
}
.greenFont {
  color: $greenColor !important;
}
.redFont {
  color: $redColor !important;
}
.warmerRedFont {
  color: $warmerRedColor !important;
}
.yellowFont {
  color: $yellowColor !important;
}
.mainFont {
  color: $mainColor !important;
}
.mainLightFont {
  color: $mainLightColor !important;
}

.content-container {
  margin-top: $contentMargin;
}

.modal-content-container {
  margin: 2.1rem;
}

.editIconSize {
  background-size: 1.07rem 1.07rem !important;
  cursor: pointer;
}

.editIconColor {
  color: $yellowColor;
  cursor: pointer;
  font-size: 2.8rem;
  font-weight: 700;
}

.inputBox {
  padding: 0.5rem;
  outline: none;
  border: none;
  border-radius: $btnRadius;
  background-color: $whiteColor;
  color: $inputColor;
  @include normal;

  &:focus {
    box-shadow: $focusShadow;
  }

  &.big {
    padding: 1rem;
  }

  &.round {
    border-radius: 1000px;
  }
}

.btn {
  &.plain,
  &.plain.main {
    @include btn(transparent, $mainColor);
    border-width: 0 !important;
    &:active:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.plain.mainDark {
    @include btn(transparent, $mainColorDark);
  }

  &.plain.mainDarker {
    @include btn(transparent, $mainColorDarker);
  }

  &.plain.yellow {
    @include btn(transparent, $yellowColor);
  }

  &.plain.white {
    @include btn(transparent, $whiteColor);
  }

  &.plain.coolerBlue {
    @include btn(transparent, $coolerBlueColor);
  }

  &.plain.red {
    @include btn(transparent, $redColor);
  }

  &.plain.warmerRed {
    @include btn(transparent, $warmerRedColor);
  }

  &.plain.greyer {
    @include btn(transparent, $greyerColor);
  }

  &.placeholder {
    @include btn(transparent, transparent);
    visibility: hidden;

    &:before {
      content: "?";
    }
  }

  &.grey {
    @include btn($greyColor);
  }

  &.white {
    @include btn($whiteColor, $greyestColor, $greyColor);
  }

  &.blue {
    @include btn($blueColor);
  }

  &.main {
    @include btn($mainColor);
  }

  &.coolerBlue {
    @include btn($coolerBlueColor);
  }

  &.blueGrey {
    //TODOricky remove?
    @include btn($blueGreyColor, $mainColorDark);
  }

  &.yellow {
    @include btn($yellowColor);
  }

  &.red {
    @include btn($redColor);
  }

  &.warmerRed {
    @include btn($warmerRedColor);
  }

  &.thinBorder {
    border-width: $btnBorderWidthThin;
    padding: calc(#{$btnPadH} + #{$btnBorderWidth} - #{$btnBorderWidthThin}) calc(#{$btnPadV} + #{$btnBorderWidth} - #{$btnBorderWidthThin});
  }

  &:focus {
    box-shadow: $focusShadow;
  }

  &:not(.icon):active:before {
    content: "";
    position: absolute;
    display: block;
    top: -$btnBorderWidth;
    left: -$btnBorderWidth;
    right: -$btnBorderWidth;
    bottom: -$btnBorderWidth;
    background-color: $whiteLayerColor;
    border-color: $whiteLayerColor;
    border-radius: $btnRadius;
    z-index: 10;
  }

  &.thin {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    line-height: 1;
  }

  &.round {
    border-radius: 1000px !important;

    &:active:before {
      border-radius: 1000px !important;
    }
  }

  &.rightBtn {
    text-align: right;
    padding-right: 0;
  }

  &.leftBtn {
    text-align: left;
    padding-left: 0;
  }

  &:disabled {
    opacity: $disabledOp;
  }

  &.cross {
    text-align: center;
    @include btn(transparent, $mainColor, $mainColor);

    &:after {
      content: "\00d7";
      display: inline-block;
      font-size: 2rem;
      color: inherit;
      line-height: 1.2rem;
      height: 1.5rem;
      width: 1.5rem;
      @include transformCenter;
    }

    &.main {
      @include btn(transparent, $mainColor, $mainColor);
    }

    &.yellow {
      @include btn(transparent, $yellowColor, $yellowColor);
    }

    &.greyer {
      @include btn(transparent, $greyerColor, $greyerColor);
    }
  }

  &.minus {
    @include btn(transparent, $redColor, $redColor);
    text-align: center;

    &:after {
      content: "-";
      display: inline-block;
      font-size: 2rem;
      color: inherit;
      line-height: 1.2rem;
      height: 1.5rem;
      width: 1.5rem;
      @include transformCenter;
    }
  }
}

.icon {
  width: auto;
  border-width: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &.smallerInfo {
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    border: 1px solid $yellowColor;
    color: $yellowColor;
    @include smallest;
    text-align: center;

    &:after {
      content: "i";
      color: inherit;
    }
  }
  &.info {
    display: inline-block;
    width: 1.1rem;
    height: 1.1rem;
    border: 2px solid $yellowColor;
    color: $yellowColor;
    @include small;
    text-align: center;

    &:after {
      content: "i";
      color: inherit;
    }
  }
  &.handle {
    display: block;
    width: 1rem;
    height: 0.8rem;
    position: relative;
    border-top: 0.1rem solid $mainColor;
    border-bottom: 0.1rem solid $mainColor;
    &:before {
      content: " ";
      display: block;
      height: 1px;
      width: 100%;
      border-top: 0.1rem solid $mainColor;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &.mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  &.round {
    border-radius: 100%;
  }
  &.detail {
    @include detailBg;
  }
  &.refresh {
    @include refreshBg;
  }
  &.logo {
    @include logoBg;
  }
  &.shortLogo {
    @include shortLogoBg;
  }
  &.msLogo {
    @include msLogoBg;
  }
  &.success {
    @include successYellowBg;
  }
  &.star1 {
    @include star1Bg;
  }
  &.star2 {
    @include star2Bg;
  }
  &.star3 {
    @include star3Bg;
  }
  &.star4 {
    @include star4Bg;
  }
  &.star5 {
    @include star5Bg;
  }
  &.eye {
    @include eyeBg;
  }
  &.sideMenu.dashboard {
    @include sideMenuDashboardIcon;
  }

  &.sideMenu.insights {
    @include sideMenuInsightsIcon;
  }

  &.sideMenu.accountUsers {
    @include sideMenAccountUserIcon;
  }
  &.profile {
    @include profileBg;
  }
  &.humidity {
    @include humidityBg;
  }
  &.temperature {
    @include temperatureBg;
  }
  &.temperature.main {
    @include temperatureBg($mainColorCode);
  }
  &.leave {
    @include leaveBg;
  }
  &.pen {
    @include penYellowBg;
  }
  &.remove {
    @include removeYellowBg;
  }
  &.decrease,
  &.decrease.yellow {
    @include decreaseYellowBg;
  }
  &.increase,
  &.increase.yellow {
    @include decreaseYellowBg;
    transform: rotate(180deg);
  }
  &.warning {
    @include warningYellowBg;
  }
  &.crossThin {
    @include crossYellowBg;
  }
  &.iaq {
    @include iaqWhiteBg;
    &.yellow {
      @include iaqYellowBg;
    }
  }
  &.iaqBlue {
    @include iaqBlueBg;
  }
  &.star {
    @include starWhiteBg;
    &.yellow {
      @include starYellowBg;
    }
  }
  &.aircon {
    @include airconWhiteBg;
    &.blue {
      @include airconBlueBg;
    }
    &.yellow {
      @include airconYellowBg;
    }
  }
  &.button {
    @include buttonWhiteBg;
    &.blue {
      @include buttonBlueBg;
    }
    &.yellow {
      @include buttonYellowBg;
    }
  }
  &.sensor {
    @include sensorWhiteBg;
    &.blue {
      @include sensorBlueBg;
    }
    &.yellow {
      @include sensorYellowBg;
    }
  }
  &.fan {
    @include fanWhiteBg;
    &.blue {
      @include fanBlueBg;
    }
    &.yellow {
      @include fanYellowBg;
    }
  }
  &.switch {
    @include switchWhiteBg;
    &.blue {
      @include switchBlueBg;
    }
    &.yellow {
      @include switchYellowBg;
    }
  }
  &.lightOff {
    @include lightOffBg;
  }
  &.lightOff.blue {
    @include lightOffBg($blueColorCode);
  }
  &.lightOn {
    @include lightOnBg;
  }
  &.lightOn.yellow {
    @include lightOnBg($yellowColorCode);
  }
  &.lightOn.blue {
    @include lightOnBg($blueColorCode);
  }

  &.downArrow.main {
    @include downArrowBg($mainColorCode);
  }
  &.downArrow.yellow {
    @include downArrowBg($yellowColorCode);
  }
  &.upArrow.main {
    transform: rotate(180deg);
    @include downArrowBg($mainColorCode);
  }
  &.upArrow.yellow {
    transform: rotate(180deg);
    @include downArrowBg($yellowColorCode);
  }
  &.rightArrow.main {
    transform: rotate(-90deg);
    @include downArrowBg($mainColorCode);
  }
  &.rightArrow.yellow {
    transform: rotate(-90deg);
    @include downArrowBg($yellowColorCode);
  }
  &.backArrow.main {
    transform: rotate(90deg);
    @include downArrowBg($mainColorCode);
  }
  &.backArrow.yellow {
    transform: rotate(90deg);
    @include downArrowBg($yellowColorCode);
  }
  &.tick {
    &.yellow {
      @include tickBg($yellowColorCode);
    }
    &.green {
      @include tickBg($greenColorCode);
    }
  }
  &.stop {
    @include stopHandYellowBg;
  }
  &.clock {
    @include clockWhiteBg;
  }
  &.energy {
    @include energyWhiteBg;
  }
  &.requestSummary {
    @include requestSummaryWhiteBg;
  }
  &.controlOthers {
    @include controlOthersWhiteBg;
  }
  &.locationMark {
    transform: translate(-50%, -100%);
    @include locationMarkBg;
  }

  &.hotdesk,
  &.HOTDESK {
    border-radius: 0;
    @include hotdeskWhiteBg;
    &.yellow {
      @include hotdeskYellowBg;
    }
    &.main {
      @include hotdeskBg;
    }
  }
  &.floor,
  &.FLOOR {
    border-radius: 0;
    @include floorWhiteBg;
    &.yellow {
      @include floorYellowBg;
    }
    &.main {
      @include floorBg;
    }
  }
  &.workstation,
  &.WORKSTATION,
  &.fixdesk,
  &.FIXDESK {
    border-radius: 0;
    @include workstationWhiteBg;
    &.yellow {
      @include workstationYellowBg;
    }
    &.main {
      @include workstationBg;
    }
  }
  &.room,
  &.ROOM {
    border-radius: 0;
    @include roomWhiteBg;
    &.yellow {
      @include roomYellowBg;
    }
    &.main {
      @include roomBg;
    }
  }

  &.empty {
    @include emptyBg;
  }
  &.search {
    @include searchBg;
  }

  &.clearday,
  &.clearnight {
    @include sunnyBg;
  }
  &.partlycloudyday,
  &.partlycloudynight {
    @include partlycloudyBg;
  }
  &.cloudy,
  &.wind {
    @include cloudyBg;
  }
  &.rain {
    @include rainBg;
  }
  &.snow {
    @include snowBg;
  }
  &.hail {
    @include hailBg;
  }
  &.sleet {
    @include sleetBg;
  }
  &.fog {
    @include fogBg;
  }
  &.home {
    @include controlWhiteBg;
  }
  &.admin {
    @include adminWhiteBg;
  }
  &.insights {
    @include insightsWhiteBg;
  }
  &.insights.collapseOpen {
    @include insightsCollapseOpen;
  }
  &.insights.collapseClose {
    @include insightsCollapseClose;
  }
  &.dashboard {
    @include dashboardWhiteBg;
  }
  &.dashboard.star {
    @include dashboardwidgetStar;
  }
  &.dashboard.cartoon1 {
    @include dashboardwidgetCartoonGrade1;
  }
  &.dashboard.cartoon2 {
    @include dashboardwidgetCartoonGrade2;
  }
  &.dashboard.cartoon3 {
    @include dashboardwidgetCartoonGrade3;
  }
  &.dashboard.cartoon4 {
    @include dashboardwidgetCartoonGrade4;
  }
  &.dashboard.cartoon5 {
    @include dashboardwidgetCartoonGrade5;
  }
  &.dashboard.headerEllipsis {
    @include headerEllipsisIcon;
  }
  &.dashboard.delete {
    @include headerDeleteIcon;
  }
  &.dashboard.duplicate {
    @include headerDuplicateIcon;
  }

  &.thunderstorm,
  &.tornado {
    @include thunderstormBg;
  }

  &.editor {
    @include editorBg;
  }
  &.viewer {
    @include viewerBg;
  }
  &.owner {
    @include ownerBg;
  }

  &.home.main {
    @include blueHomeBg;
  }
  &.location.main {
    @include blueLocationBg;
  }
  &.scene {
    &.main {
      @include blueSceneBg;
    }
    &.yellow {
      @include yellowSceneBg;
    }
  }
  &.account.main {
    @include blueAccountUserMaleBg;
  }
  &.profile.adminisator {
    @include profileAdminisatorIcon;
  }

  &.editIcon {
    @include editIcon;
  }

  &.cancelIcon {
    @include cancelIcon;
  }

  &.saveIcon {
    @include saveIcon;
  }

  &.map,
  &.map.yellow {
    @include mapYellowBg;
  }
  &.list,
  &.list.yellow {
    @include listYellowBg;
  }

  &.dashboard.widgetEdit {
    @include dashboardWidgetEdit;
  }

  &.dashboard.widgetMove {
    @include dashboardwidgetMove;
  }

  &.dashboard.widgetExport {
    @include dashboardwidgetExport;
  }

  &.dashboard.widgetCodeWall {
    @include dashboardwidgetCodeWall;
  }
  &.dashboard.widgetConflict {
    @include dashboardwidgetConflict;
  }
  &.dashboard.widgetLightOn {
    @include dashboardwidgetlightOn;
  }
  &.dashboard.aircon {
    @include dashboardwidgetAircon;
  }
  &.dashboard.widgetCo2 {
    @include dashboardwidgetCo2;
  }
  &.dashboard.widgetWater {
    @include dashboardwidgetWater;
  }
  &.dashboard.widgetTemperature {
    @include dashboardwidgetTemperature;
  }

  &.insights.selectors {
    @include insightsSelectors;
  }
  &.insights.leftArrow {
    @include LeftArrowInsightsIcon;
  }
  &.insights.rightArrow {
    @include RightArrowInsightsIcon;
  }
  &.insights.menuExpand {
    @include insightsMenuExpand;
  }
  &.insights.share {
    @include insightsShare;
  }
  &.insights.datapoint {
    @include insightsDatapoint;
  }
  &.insights.sites {
    @include insightsSites;
  }
  &.insights.pov {
    @include insightsPov;
  }
  &.insights.menuPackup {
    @include insightsMenuPackUp;
  }

  &.insights.locations {
    @include insightsLocations;
  }
  &.insights.clearAll {
    @include insightsLocationsClearAll;
  }
  &.insights.expandIcon {
    @include insightsSelectExpandIcon;
  }
  &.insights.refresh {
    @include insightsLocationsRefresh;
  }
  &.insights.scrubber {
    @include insightsScrubber;
  }
  &.insights.scrubberActive {
    @include insightsScrubberActive;
  }
  &.insights.datatable {
    @include insightsDataTable;
  }
  &.insights.datatableSearch {
    @include insightsDataTableSearch;
  }

  &.insights.barChart {
    @include insightsTrendIconBarChart;
  }
  &.insights.barChartActive {
    @include insightsTrendIconBarChartActive;
  }
  &.insights.lineChart {
    @include insightsTrendIconLinechart;
  }
  &.insights.lineChartActive {
    @include insightsTrendIconLinechartActive;
  }
  &.insights.pieChart {
    @include insightsTrendIconPieChart;
  }
  &.insights.pieChartActive {
    @include insightsTrendIconPieChartActive;
  }
  &.insights.heatmap {
    @include insightsTrendIconHeatMapChart;
  }
  &.insights.heatmapActive {
    @include insightsTrendIconHeatMapChartActive;
  }
  &.insights.scorecard {
    @include insightsTrendIconScoreCardChart;
  }
  &.insights.scorecardActive {
    @include insightsTrendIconScoreCardChartActive;
  }
  &.insights.temperature {
    @include insightsTrendIconTemperatureChart;
  }
  &.insights.temperatureActive {
    @include insightsTrendIconTemperatureChartActive;
  }
  &.insights.breakdown {
    @include insightsTrendIconBreakdownChart;
  }
  &.insights.breakdownActive {
    @include insightsTrendIconBreakdownChartActive;
  }

  &.insights.collapseRight {
    @include insightsCollapseRightIcon;
  }
  &.insights.collapseDarkRight {
    @include insightsCollapseDarkRightIcon;
  }
}

.btn.icon {
  &:hover {
    background-color: $darkLayerColor;
  }

  &:active {
    background-color: $whiteLayerColor;
  }

  &:disabled {
    background-color: $whiterLayerColor !important;
    opacity: $disabledOp !important;
  }
}

/*.triangle {
  &.up::before {
    @include triangle($redColor, 0deg, 1.6rem);
  }
  &.down::before {
    @include triangle($lightBlueColor, 180deg, 1.6rem);
  }
}*/

.dot {
  &.red::before {
    @include dot($redColor);
  }

  &.green::before {
    @include dot($greenColor);
  }

  &.yellow::before {
    @include dot($yellowColor);
  }

  &.blue::before {
    @include dot($blueColor);
  }

  &.main::before {
    @include dot($mainColor);
  }

  &.coolerBlue::before {
    @include dot($coolerBlueColor);
  }

  &.red::before {
    @include dot($redColor);
  }

  &.warmerRed::before {
    @include dot($warmerRedColor);
  }

  &.grey::before,
  &::before {
    @include dot($greyColor);
  }

  &.placeholder::before {
    @include dot(transparent);
  }
}

.whiteCard {
  background-color: $whiteColor;
  &:not(.rectangle) {
    border-radius: $smallCardRadius;
  }
}

.mainCard {
  border-radius: $cardRadius;
  background-color: $mainColor;
  color: $whiteColor;
  overflow: hidden;
  &.rectangle {
    border-radius: 0;
  }
  &.smallCorner {
    border-radius: $smallCardRadius;
  }
}

/* scroll bar */
::-webkit-scrollbar {
  width: $scrollBarWidth; /* 滚动条宽度 */
  height: $scrollBarWidth; /* 滚动条宽度 */
}
::-webkit-scrollbar-track {
  background-color: $greyColor; /* 滚动条轨道背景色 */
}
::-webkit-scrollbar-thumb {
  background-color: $yellowColor; /* 滚动条滑块背景色 */
}
::-webkit-scrollbar-thumb:hover {
  background-color: $yellowColor; /* 滚动条滑块悬停时背景色 */
}

/* Internet Explorer */
::-ms-scrollbar {
  width: $scrollBarWidth; /* 滚动条宽度 */
  height: $scrollBarWidth; /* 滚动条宽度 */
}
::-ms-scrollbar-track {
  background-color: $greyColor; /* 滚动条轨道背景色 */
}
::-ms-scrollbar-thumb {
  background-color: $yellowColor; /* 滚动条滑块背景色 */
}
::-ms-scrollbar-thumb:hover {
  background-color: $yellowColor; /* 滚动条滑块悬停时背景色 */
}

.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
/* scroll bar */

.ReactModal__Overlay {
  z-index: 1000;
  background-color: $darkerLayerColor !important;
}

.ReactModal__Content {
  inset: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 100%;
  width: 100%;
  box-shadow: $modalShadow;
}

/*.swiper {
  height: 100%;
  .swiper-pagination {
    line-height: 1;
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: $ctrlBgColor;
      &.swiper-pagination-bullet-active {
        background-color: $ctrlPrimaryColor;
      }
    }
  }
}*/

/*.btn-group {
  > .btn-check:focus + .btn,
  > .btn-check + .btn:focus {
    box-shadow: $focusShadow;
  }
  > .btn-check:disabled + .btn {
    opacity: $disabledOp;
  }
  > .btn:not(:last-child):active:before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  > .btn:not(:first-child):active:before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}*/

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
  &.disabled {
    cursor: default;
    opacity: $disabledOp;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-color: $greyColor;
  background-color: $whiteColor;
  &:checked,
  &.main:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23#{$mainColorCode}'/%3e%3c/svg%3e");
  }
  &.yellow:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23#{$yellowColorCode}'/%3e%3c/svg%3e");
  }
}

.underline {
  border-bottom: $dividerWidth solid $lightDividerColor;
  &.yellowBorder {
    border-bottom-color: $yellowColor;
  }
  &.greyerBorder {
    border-bottom-color: $greyerColor;
  }
  &.greyestBorder {
    border-bottom-color: $greyestColor;
  }
}

.separatorH + .separatorH {
  border-top: $dividerWidth solid $lightDividerColor;
  &.thick {
    border-top-width: $dividerWidthThick;
  }
  &.yellowBorder {
    border-top-color: $yellowColor;
  }
  &.greyerBorder {
    border-top-color: $greyerColor;
  }
  &.greyestBorder {
    border-top-color: $greyestColor;
  }
}

.separatorV + .separatorV {
  border-left: $dividerWidth solid $lightDividerColor;
  &.thick {
    border-left-width: $dividerWidthThick;
  }
  &.yellowBorder {
    border-left-color: $yellowColor;
  }
  &.greyestBorder {
    border-left-color: $greyestColor;
  }
}

.blockLayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $whiterLayerColor;
}

.capital {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.w12 {
  width: 12rem !important;
}
.w10 {
  width: 10rem !important;
}
.w8 {
  width: 8rem !important;
}
.w6 {
  width: 6rem !important;
}
.w4 {
  width: 4rem !important;
}
.minw12 {
  min-width: 12rem !important;
}
.minw10 {
  min-width: 10rem !important;
}
.minw8 {
  min-width: 8rem !important;
}
.minw6 {
  min-width: 6rem !important;
}
.minw4 {
  min-width: 4rem !important;
}

.page-layout-container {
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

/* ant */
/* dropdown */
.ant-select {
  font-family: inherit;
  line-height: normal;
  height: auto !important;
  width: 100%;
  color: $greyestColor;
  @include normalWeak;
  &.round {
    .ant-select-selector {
      border-radius: 1000px;
    }
  }
  &.rectangle {
    .ant-select-selector {
      border-radius: 0px;
    }
  }
  &.noBorder {
    .ant-select-selector {
      border-width: 0;
    }
  }
  .ant-select-selector {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    padding: 0.5rem 0.7rem !important;
    height: auto !important;
    line-height: normal;
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: normal;
    }
    &:after {
      line-height: normal !important;
    }
  }
  .ant-select-selection-item {
    font-size: inherit;
    font-weight: inherit;
  }
  .ant-select-arrow {
    margin-top: 0;
    height: auto;
    transform: translateY(-50%);
  }
}
.ant-select-dropdown {
  .ant-select-item-option {
    padding: 0.5rem;
    color: $greyestColor;
    @include normal;
    &.ant-select-item-option-selected,
    &.ant-select-item-option-active {
      color: $greyestColor;
      font-weight: 400;
      background-color: $yellowColor;
    }
  }
}

.drop-down-container {
  padding-top: 1rem !important;

  .ant-dropdown-menu {
    border-radius: 4px;

    .ant-dropdown-menu-item {
      margin: 0.5rem 0.8rem 0.5rem 0.8rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      // padding: 2px px;
      .ant-dropdown-menu-title-content button {
        color: $yellowColor;
      }
    }
  }

  .ant-dropdown-menu .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu .ant-dropdown-menu-item-active {
    color: $whiteColor;
    background-color: $yellowColor;

    .ant-dropdown-menu-title-content button {
      color: $whiteColor;
    }
  }
}

.drop-down-size {
  .ant-dropdown-menu-item {
    padding: 0px !important;
  }
}
/* ant */
