@import "../../variables";

.dropdown.react-dropdown-select {
  border-radius: $btnRadius;
  background-color: $whiteColor;
  color: $greyestColor;
  border-color: $greyestColor;
  min-height: 0;
  &:hover,
  &:focus,
  &:focus-within {
    border-color: $greyestColor;
  }
  padding: 0.5rem 0.7rem;
  &:focus,
  &:focus-within {
    box-shadow: $focusShadow;
  }
  .react-dropdown-select-input {
    display: none;
  }
  .react-dropdown-select-content,
  .react-dropdown-select-content > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .react-dropdown-select-dropdown-handle {
    padding-left: 0.5rem;
    > span {
      display: block;
    }
  }
  &.round {
    border-radius: 1000px;
  }
  &.main {
    .react-dropdown-select-content {
      color: $mainColor;
    }
    border-color: $mainColor;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $mainColor;
    }
    .react-dropdown-select-dropdown,
    &.react-dropdown-select-item-selected {
      > *:hover:not(.disabled) {
        background-color: $yellowColor;
      }
    }
  }
  &.yellow {
    .react-dropdown-select-content {
      color: $yellowColor;
    }
    border-color: $yellowColor;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $yellowColor;
    }
    .react-dropdown-select-dropdown {
      > *:hover:not(.disabled),
      &.react-dropdown-select-item-selected {
        background-color: $yellowColor;
      }
    }
  }
  &.yellow,
  &.main {
    &.yellowBorder {
      border-color: $yellowColor;
      &:hover,
      &:focus,
      &:focus-within {
        border-color: $yellowColor;
      }
    }
    &.mainBorder {
      border-color: $mainColor;
      &:hover,
      &:focus,
      &:focus-within {
        border-color: $mainColor;
      }
    }
  }
  &.noBorder,
  &.noBorder.yellow,
  &.noBorder.main {
    border-color: $whiteColor;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $whiteColor;
    }
  }
  &.thin {
    padding: 0.4rem 0.5rem;
  }
  .react-dropdown-select-dropdown {
    > * {
      font-weight: 400;
      padding: 0.5rem;
      border-bottom: none;
      &.disabled {
        opacity: $disabledOp;
      }
      &:hover:not(.disabled),
      &.react-dropdown-select-item-selected {
        cursor: pointer;
        background-color: $yellowColor;
        color: $greyestColor !important;
      }
    }
  }
}
