@import "../../variables";

.modalLayout {
  .ReactModal__Content {
    background-color: transparent !important;
    .modalMidRow {
      .modalContent {
        background-color: $whiteColor !important;
        border-color: $greyestColor !important;
        border-radius: $cardRadius !important;
        width: 30rem;
        /*height: 100%;*/
      }
    }
  }
}
