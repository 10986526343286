@import "../../variables";

.msgModal {
  .ReactModal__Content {
    border: none;
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    > div {
      background-color: inherit;
      width: inherit;
      height: inherit;
      > .container {
        @include transformCenter(5%);
        border-radius: 1rem;
        background-color: $whiteColor;
        margin: auto;
        width: 90%;
        max-width: 400px;
      }
    }
  }
}
