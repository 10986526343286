@import "./variables";

.profilePage,
.ant-popover,
.ant-picker,
.ant-picker-dropdown,
.ant-picker-range,
.ant-radio-wrapper,
.changePasswordModal,
.insights-modal,
.dashboardPage,
.dashboard-portal-container,
.insightsPage {
  .ant-input-affix-wrapper {
    border-radius: 3px;
  }

  .ant-select {
    height: 40px;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:focus-within,
  .ant-input:focus,
  .ant-input:focus-within,
  .ant-switch.ant-switch-checked,
  .ant-select-focused.ant-select .ant-select-selector,
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
  .ant-select.ant-select-in-form-item:hover .ant-select-selector,
  .ant-select.ant-select-in-form-item:active .ant-select-selector,
  .ant-picker-focused.ant-picker,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker:hover,
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $yellowColor !important;
  }
  .dropdown.react-dropdown-select .react-dropdown-select-dropdown > *:hover:not(.disabled),
  .dropdown.react-dropdown-select .react-dropdown-select-dropdown > *.react-dropdown-select-item-selected,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-range .ant-picker-active-bar,
  .ant-btn-primary,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $yellowColor;
  }

  .dropdown.react-dropdown-select .react-dropdown-select-input {
    width: 100%;
    display: block;
    font-size: 1rem;
  }

  .dropdown.react-dropdown-select .react-dropdown-select-input::placeholder,
  .ant-picker-today-btn,
  .ant-picker-today-btn:active,
  .ant-picker-today-btn:hover {
    width: 100%;
    color: $yellowColor !important;
  }

  .react-dropdown-select .react-dropdown-select-input {
    display: inline-block;
  }

  .ant-message {
    top: 50% !important;
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: $yellowColor !important;
    color: unset;
  }

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: $yellowColor !important;
    color: white !important;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: $yellowColor !important;
    border-color: $yellowColor !important;
  }

  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: $yellowColor !important;
  }

  .operation-container {
    .operation-btn {
      width: 7rem;
      height: 2.5rem;
      background-color: #edf2f7;
      color: rgba(98, 98, 98, 1);
      font-size: 1rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 0px 1rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      font-weight: 600;
      border: none;
    }

    .operation-btn.save {
      background-color: $yellowColor !important;
      color: $whiteColor;
      margin-left: 1.25rem;
    }

    .operation-btn:last-child {
      color: white;
    }

    .operation-btn:last-child:hover {
      color: $whiteColor;
    }

    .operation-btn:first-child:hover {
      color: rgba(98, 98, 98, 1);
    }
  }
  .ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after,
  .ant-tree-checkbox:hover {
    background-color: $yellowColor !important;
    border-color: $yellowColor !important;
  }
}

body {
  .ant-checkbox-wrapper {
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    &:not(.ant-checkbox-wrapper-disabled):hover {
      .ant-checkbox-inner,
      .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        border-color: $yellowColor;
      }
      .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: $yellowColor;
      }
    }
    .ant-checkbox {
      border-radius: $smallBtnRadius;
      + span {
        padding-inline-start: 0.75rem;
        padding-inline-end: 0;
      }
      .ant-checkbox-inner {
        border-color: $yellowColor;
        border-radius: inherit;
      }
      .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
        outline: none;
        box-shadow: $focusShadow;
      }
      &.ant-checkbox-disabled {
        opacity: $disabledOp;
        .ant-checkbox-inner:after {
          border-color: $whiteColor;
        }
      }
      &.ant-checkbox-checked .ant-checkbox-inner {
        background-color: $yellowColor;
        border-color: $yellowColor;
      }
      &.ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: $yellowColor;
      }
    }
  }
  .ant-spin .ant-spin-dot-item {
    background-color: $yellowColor;
  }
  .ant-list {
    font-size: inherit;
    color: inherit;
    div[class^="ant-spin"] {
      font-size: inherit;
      color: inherit;
      .ant-list-item {
        -webkit-border-after: none;
        border-block-end: none;
        font-size: inherit;
        color: inherit;
        padding: 0;
        .ant-list-item-action {
          margin-inline-start: 0;
          li {
            padding: 0 0 0 0.5rem;
            .ant-list-item-action-split {
              display: none;
            }
          }
        }
      }
    }
  }
}
