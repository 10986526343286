@import "../../variables";

.changePasswordModal {
  .ReactModal__Content {
    background-color: $whiteColor !important;
    border-radius: 1rem !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .content-container {
      margin-top: 3.5rem;

      .title {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
      }
    }
  }

  .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: $whiteColor;
    background-color: transparent;
  }

  .desc {
    font-size: 1rem;
    font-weight: 400;
    color: #afafaf;
    font-size: 1rem;
    padding-bottom: 5px;
    font-style: normal;
    line-height: normal;
  }

  .operation-btn {
    width: 100%;
    border-radius: 25px;
    padding: 4px 0px;
    background-color: $yellowColor;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .ant-btn {
      width: 90%;
      color: $whiteColor;
    }
  }

  .pwdChecker {
    width: 66.66666666666666%;
    margin: auto;
  }

  .operation-link {
    width: 90%;
    margin-left: 5%;
    border-radius: 25px;
    padding-top: 20px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $yellowColor;
    font-weight: 500;
    cursor: pointer;
  }

  .react-dropdown-select .react-dropdown-select-input {
    display: inline-block;
  }

  .ant-list-split .ant-list-item {
    border-block-end: none;
  }

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .react-dropdown-select-dropdown {
    > div:hover:not(.disabled) {
      > .greyerFont,
      > .greyestFont {
        color: $whiteColor !important;
      }
    }
  }
}

$width: 500px;
$height: 571px;
@media (min-width: 768px) {
  .changePasswordModal {
    .ReactModal__Content {
      top: calc(50% - #{$height} / 2) !important;
      left: calc(50% - #{$width} / 2) !important;
      height: $height !important;
      width: $width !important;
      border-radius: 1rem !important;
    }
  }
}
